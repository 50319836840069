export const SocketConnect = (
  origin: string,
  mailerNotifiction: (notification: any) => void
) => {
  const ws = new WebSocket("ws://localhost:5000");

  ws.onopen = function open() {
    console.log("Connected to server");
    const message = { client: origin };
    ws.send(JSON.stringify(message));
  };

  ws.onmessage = function incoming(event) {
    const data = JSON.parse(event.data);
    if (origin === data.origin) {
      mailerNotifiction(data.message.to[0]);
    }
  };

  return () => {
    ws.close();
  };
};
