import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { FaPlay, FaStop, FaUpload } from "react-icons/fa";
import { SocketConnect } from "../SocketConnect";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

interface MailerFormProps {
  id: number;
  handleFormDataChange: (id: number, formData: FormData) => void;
}

interface FormData {
  email: string;
  password: string;
  subject: string;
  salutation: string;
  closingMessage: string;
  message: string;
}

const MailerForm: React.FC<MailerFormProps> = ({ id, handleFormDataChange }) => {
  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
    subject: "",
    salutation: "",
    closingMessage: "",
    message: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = { ...prev, [name]: value };
      handleFormDataChange(id, updatedFormData);
      return updatedFormData;
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    console.log(`Mailer ${id} data:`, formData);
  };



  return (
    <div className="bg-[#1B1B1B] p-4 rounded-lg shadow-lg w-[297.5px] h-[614px] flex flex-col justify-between">
      <h2 className="text-white mb-4">MAILER {id}</h2>
      <form onSubmit={handleSubmit} className="space-y-4 flex-grow flex flex-col">
        <input
          type="email"
          name="email"
          placeholder="SMTP EMAIL"
          className="w-full p-2 rounded bg-gray-700 text-white"
          value={formData.email}
          onChange={handleChange}
        />
        <input
          type="password"
          name="password"
          placeholder="SMTP PASSWORD"
          className="w-full p-2 rounded bg-gray-700 text-white"
          value={formData.password}
          onChange={handleChange}
        />
        <input
          type="text"
          name="subject"
          placeholder="Subject"
          className="w-full p-2 rounded bg-gray-700 text-white"
          value={formData.subject}
          onChange={handleChange}
        />
        <div className="flex space-x-4">
          <input
            type="text"
            name="salutation"
            placeholder="Salutation"
            className="w-1/2 p-2 rounded bg-gray-700 text-white"
            value={formData.salutation}
            onChange={handleChange}
          />
          <input
            type="text"
            name="closingMessage"
            placeholder="Closing message"
            className="w-1/2 p-2 rounded bg-gray-700 text-white"
            value={formData.closingMessage}
            onChange={handleChange}
          />
        </div>
        <textarea
          name="message"
          placeholder="Enter message"
          className="w-full p-2 rounded bg-gray-700 text-white flex-grow"
          value={formData.message}
          onChange={handleChange}
        />
        <div className="flex items-center space-x-4">
          <button type="button" className="bg-red-500 p-2 rounded">
            <FaStop className="text-white" />
          </button>
          <button type="submit" className="bg-green-500 p-2 rounded">
            <FaPlay className="text-white" />
          </button>
        </div>
        <button type="button" className="w-full bg-gray-600 p-2 rounded flex items-center justify-center space-x-2">
          <FaUpload className="text-white" />
          <span className="text-white">UPLOAD FILE</span>
        </button>
      </form>
    </div>
  );
};

export default MailerForm;
