import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { FileUploadForm } from "./FileUploader";
import { Auths } from "./Auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FileVerification } from "./FileVerifications";
import { App2 } from "./components/new";

const App: React.FC = () => {
  const toastContainerStyle: React.CSSProperties = {
    width: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  };

  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  return (
    <div className="App">
      <Router>
        <ToastContainer
          style={toastContainerStyle}
          position="top-center"
          autoClose={false}
        />
        <Routes>
          <Route path="/" element={<Navigate to={token ? "/mailer" : "/auth"} />} />
          <Route path="/auth" element={!token ? <Auths /> : <Navigate to="/mailer" />} />
          <Route path="/mailer" element={token ? <FileUploadForm /> : <Navigate to="/auth" />} />
          <Route path="/verify" element={<FileVerification />} />
          <Route path="/v2/mailer" element={<App2 />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
