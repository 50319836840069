import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../Auth";
import { MovingIcon } from "../FileUploader";
import { SocketConnect } from "../SocketConnect";

export const Form1: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState("No file");
  const [content, setContent] = useState("");
  const [subject, setSubject] = useState("");
  const [greetings, setGreetings] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [states, setStates] = useState<string>("IDLE");
  const [message1, setMessage1] = useState<string>();

  useEffect(() => {
    const storedUserData = localStorage.getItem("token");
    if (storedUserData) {
      setToken(storedUserData);
    }
  }, [token, states]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files && event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      setFileName(uploadedFile.name);
    }
  };

  const mailerNotification = (notification: any) => {
    setMessage1(notification);
  };

  const resetForm = () => {
    setContent("");
    setSubject("");
    setEmail("");
    setPassword("");
    setGreetings("");
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!file) {
      toast.info("MAILER(1) : Please select a file!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("content", content);
    formData.append("subject", subject);
    formData.append("greetings", greetings);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("origin", "mailer1");

    const sendMail = async (formData: any) => {
      setStates("PENDING");
      try {
        const response = await axios.post(`${baseUrl}/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        });

        if (response.status === 200) {
          setStates("SUCCESS");
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/auth";
          toast.error("User session expired!.");
        } else {
          toast.error(
            `Mailer(1): ${error?.response?.data.message}` ||
              "Mailer(1): something went wrong"
          );
          setStates("FAILED");
        }
      }
    };
    await sendMail(formData);
    resetForm();
  };

  useEffect(() => {
    if (states === "SUCCESS") {
      toast.success("Mailer(1) : All emails sent successfully");
      setStates("IDLE");
      setMessage1("");
    } else if (states === "FAILED") {
      setStates("IDLE");
      setMessage1("");
    }
  }, [states]);

  useEffect(() => {
    SocketConnect("mailer1", mailerNotification);
  }, [message1]);

  return (
    <>
      <div className="bg-gray-200 bg-opacity-50 backdrop-blur-lg  px-2 py-2 rounded-lg w-[45%] h-46">
        <form onSubmit={handleSubmit}>
          <div className="relative flex justify-between">
            <div className="text-black text-lg pb-4 font-bold">MAILER(1)</div>
            {message1 ? (
              <div className="fixed right-2 top-2 py-2 px-2 flex bg-[#0060Ff]  rounded-lg text-white animate-pulse ">
                Message sent to {message1}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="flex gap-2 ">
            <div className="flex flex-col gap-4 w-full">
              <div className="flex items-center gap-8">
                <input
                  type="text"
                  id="subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  className="border-2 border border-black  w-1/2 py-2 rounded-lg px-2"
                  placeholder="SUBJECT"
                />
                <label
                  htmlFor="file1"
                  className="cursor-pointer w-1/2 truncate"
                >
                  <div className="py-2.5 px-4 border-2  border-black rounded-lg flex items-center">
                    <MovingIcon />
                    <span className="ml-2 truncate w-full text-black ">
                      {fileName}
                    </span>
                  </div>
                  <input
                    type="file"
                    id="file1"
                    className="hidden"
                    accept=".txt"
                    onChange={handleFileChange}
                  />
                </label>
              </div>
              <div className="flex items-center gap-8">
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border-2 border  border-black w-1/2   py-2 rounded-lg px-2"
                  placeholder="SMTP EMAIL"
                />
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="border-2 border  border-black w-1/2   py-2 rounded-lg px-2"
                  placeholder="SMTP PASSWORD"
                />
              </div>
              <input
                type="text"
                id="greetings"
                value={greetings}
                onChange={(e) => setGreetings(e.target.value)}
                className="border-2 border  border-black  py-2 rounded-lg px-2"
                placeholder="Enter your closing message (e.g., Thank you)"
              />
            </div>
            <textarea
              id="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="border-2 border border-black  w-1/2 py-2 rounded-lg px-2"
              placeholder="CONTENT"
            />
          </div>
          <div className="">
            <button
              className={`bg-gray-500 bg-opacity-50 backdrop-blur-lg  text-center mt-4   py-2 w-full text-lg font-semi-bold rounded-lg text-black ${
                !file ||
                !subject ||
                !email ||
                !password ||
                !content ||
                states === "PENDING"
                  ? "cursor-not-allowed "
                  : "cursor-pointer"
              } `}
              type="submit"
              disabled={
                !file ||
                !subject ||
                !email ||
                !password ||
                !content ||
                states === "PENDING"
              }
            >
              {states === "PENDING" ? "Sending..." : "Send"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
