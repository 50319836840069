import React, { useState } from "react";
import MailerForm from "./MailerForm";
import { FaPlus, FaCheck, FaMinus } from "react-icons/fa";

interface FormData {
  email: string;
  password: string;
  subject: string;
  salutation: string;
  closingMessage: string;
  message: string;
}

export const App2: React.FC = () => {
  const [mailers, setMailers] = useState<number[]>([1, 2, 3, 4]);
  const [formData, setFormData] = useState<{ [key: number]: FormData }>({});

  const addMailer = () => {
    setMailers((prev) => [...prev, prev.length + 1]);
  };

  const removeMailer = (id: number) => {
    setMailers((prev) => prev.filter((mailerId) => mailerId !== id));
    const updatedFormData = { ...formData };
    delete updatedFormData[id];
    setFormData(updatedFormData);
  };

  const handleFormDataChange = (id: number, data: FormData) => {
    setFormData((prev) => ({ ...prev, [id]: data }));
  };

  const handleConsoleLog = () => {
    console.log(formData);
  };

  return (
    <div className="bg-black h-screen w-screen flex flex-col justify-between overflow-hidden p-4">
      <div className="flex flex-row gap-8 justify-center overflow-x-auto mb-4">
        {mailers.map((id) => (
          <div key={id} className="flex flex-col items-center">
            <MailerForm id={id} handleFormDataChange={handleFormDataChange} />
            {mailers.length > 4 && (
              <button onClick={() => removeMailer(id)} className="bg-red-500 p-2 rounded text-white mt-2">
                <FaMinus />
              </button>
            )}
          </div>
        ))}
      </div>
      <div className="fixed bottom-4 left-4 flex gap-4">
        <button onClick={addMailer} className="bg-blue-500 p-2 rounded text-white flex items-center">
          <FaPlus className="mr-2" /> Add Mailer
        </button>
        <button onClick={handleConsoleLog} className="bg-green-500 p-2 rounded text-white flex items-center">
          <FaCheck className="mr-2" /> Console Log Data
        </button>
      </div>
    </div>
  );
};
