import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { baseUrl } from "./Auth";

export function FileVerification() {
  const [fileName, setFileName] = useState("No file");
  const [file, setFile] = useState<File | null>(null);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files && event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      setFileName(uploadedFile.name);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!file) {
      toast.info("Please select a file!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    const verifyFile = async (formData: any) => {
      setStates("PENDING");
      try {
        const response = await axios.post(`${baseUrl}/verify`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          setStates("SUCCESS");
        }
      } catch (error: any) {
        toast.error(error?.response?.data.message);
        setStates("FAILED");
      }
    };
    await verifyFile(formData);
  };

  const [states, setStates] = useState<string>("IDLE");
  const backRoute = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    if (states === "SUCCESS") {
      toast.success("File Verified Successfully");
      setStates("IDLE");
    } else if (states === "FAILED") {
      setStates("IDLE");
    }
  }, [states]);

  return (
    <div className="bg-black text-white">
      <div className="flex justify-end p-4">
        <button
          onClick={backRoute}
          type="submit"
          className="bg-[#0060Ff] px-6 py-2 rounded-lg"
        >
          Back
        </button>
      </div>
      <div className="bg-black h-screen w-screen text-white flex flex-col items-center justify-center">
        <div className="text-2xl font-bold">Verify Document</div>

        <form
        id="myForm"
          onSubmit={handleSubmit}
          className="flex flex-col w-full items-center gap-4"
        >
          <label htmlFor="file1" className="cursor-pointer w-1/2 truncate">
            <div className="py-2.5 px-4 border-2  border-black rounded-lg flex items-center">
              <span className="ml-2 truncate w-full text-white">
                {fileName}
              </span>
            </div>
            <input
              type="file"
              id="file1"
              className="border-2 border w-full  border-[#0060Ff] text-black  py-2 rounded-lg px-2"
              accept=".txt"
              onChange={handleFileChange}
            />
          </label>
          <button type="submit" className="bg-[#0060Ff] px-6 py-2 rounded-lg">
            {states === "PENDING" ? "Verifying..." : "Verify"}
          </button>
        </form>
      </div>
    </div>
  );
}
