import React from "react";
import { Form1 } from "./components/Form1";
import { Form2 } from "./components/Form2";
import { Form3 } from "./components/Form3";
import { Form4 } from "./components/Form4";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

export const FileUploadForm: React.FC = React.memo(() => {
  
  const CopyrightText = () => {
    const currentYear = new Date().getFullYear();
    return (
      <div>
        <p>&copy; {currentYear} BM. All rights reserved.</p>
      </div>
    );
  };

  const terminate = () => {
    localStorage.removeItem("token");
    window.location.href = "/mailer";
  }

  return (
    <div className="bg-black w-screen h-screen ">
      <div className="flex p-6 bg-gray-500 bg-opacity-50 backdrop-blur-lg  items-center  justify-between  ">
        <div className="flex items-center gap-2">
          <div className="text-white text-lg font-bold">BM</div>
        </div>
        <button onClick={terminate} className="px-6 py-2 bg-[#0060Ff] rounded-lg font-bold text-white text-sm">
          Terminate session
        </button>
      </div>

      <div className="bg-black w-full overflow-none flex flex-wrap justify-around gap-6 py-6">
        <Form1 />
        <Form2 />
        <Form3 />
        <Form4 />
      </div>
      <div className="text-gray-200 text-opacity-50 backdrop-blur-lg  flex justify-center">
        <CopyrightText />
      </div>
    </div>
  );
});

export const MovingIcon = () => {
  return (
    <motion.div
      animate={{
        y: [-5, 0, -5, 0],
        transition: { duration: 1, repeat: Infinity, repeatDelay: 10 }, 
      }}
    >
      <FontAwesomeIcon icon={faUpload} />
    </motion.div>
  );
};
