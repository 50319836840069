import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const baseUrl = "https://mailer-pu4v.onrender.com";

export const Auths = () => {
  const [token, setToken] = useState("");
  const [states, setStates] = useState<string>("IDLE");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setStates("PENDING");

    const formData = new FormData();
    formData.append("token", token);

    try {
      const response = await axios.post(
        `${baseUrl}/auth`,
        JSON.stringify({ token }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.canProceed === true) {
        setStates("SUCCESS");
        toast.success("Access token accepted!");
        localStorage.setItem("token", response.data.clientToken);
        window.location.href = "/mailer";
      } else if (response.data.canProceed === false) {
        toast.error("Invalid access token!");
      } else if (response.status === 401) {
        toast.error("User session expired!, login!");
      }

      console.log(response, "iwii");
    } catch (error) {
      console.error("Error:", error);
      setStates("FAILED");
      toast.error("Invalid Access Token!");
    }
  };

  const verifyRoute = () => {
    window.location.href = "/verify";
  }

  return (
    <div className="bg-black text-white">
      <div className="flex justify-end p-4">
        <button onClick={verifyRoute} type="submit" className="bg-[#0060Ff] px-6 py-2 rounded-lg">
          Verify Document
        </button>
      </div>

      <div className="bg-black h-screen w-screen text-white flex flex-col items-center justify-center">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col w-full items-center gap-4"
        >
          <div className="text-2xl font-bold">Access Token</div>
          <input
            type="text"
            id="token"
            value={token}
            onChange={(e) => setToken(e.target.value)}
            className="border-2 border w-1/2  border-[#0060Ff] text-black  py-2 rounded-lg px-2"
            placeholder="Token"
          />
          <button type="submit" className="bg-[#0060Ff] px-6 py-2 rounded-lg">
            {states === "PENDING" ? "Verifying..." : "Proceed"}
          </button>
        </form>
      </div>
    </div>
  );
};
